$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
//$gold: #c5a46d;
$gold: #bf9b2e;

body {
  color: #363636;
  font-family: 'Roboto', sans-serif;
}

.btn-brown {
  background-color: $gold;
  color: #fff;
}

.navbar {
  background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
  font: 18px 'Open Sans Condensed', sans-serif;
  transition: background .5s;
  img {
    height: 110px;
    transition: height .5s;
  }
  a {
    color: #fff !important;
    text-decoration: none;
    transition: color .5s;
    &:hover, &:focus {
      color: $gold !important;
    }
  }
  .navbar-toggler {
    background: rgba(11, 12, 12, 0.81);
    border-radius: 0;
    border: none;
  }
  .nav.contact {
    position: fixed;
    bottom: .5rem;
    right: .75rem;
    @media (min-width: $md) {
      right: 2rem;
    }
    @media (min-width: $lg) {
      position: static;
    }
    li {

    }
    a {
      background: rgba(11, 12, 12, 0.81);
      outline: 2px solid rgba(191, 155, 46, 0.5);
      display: inline-block;
      height: 2.5rem;
      margin-left: .5rem;
      padding: .5rem;
      text-align: center;
      transition: all .5s;
      width: 2.5rem;
    }
  }
  .navbar-collapse {
    justify-content: center;
  }
  .navbar-nav {
    font-size: 1.25rem;
    font-weight: 600;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.75);
    transition: font-size .5s;
  }
  &.scrolled {
    background: #0b0c0c;
    border-bottom: 1px solid #333533;
    //box-shadow: 0 -10px 30px $gold;
    img {
      height: 4rem;
    }
    .navbar-toggler {
      background: $gold;
    }
    .navbar-nav {
      font-size: 1rem;
    }
    //.nav.contact {
    //  a {
    //    background: $gold;
    //    &:hover, &:focus {
    //      color: #0b0c0c;
    //    }
    //  }
    //}
  }
}

#top, #top-subpage {
  //background-image: url('../public/assets/images/background2.jpg');
  background-size: cover;
  color: #fff;
  @media (min-width: $xl) {
    background-position-y: -700px;
  }
}

#top {
  height: 96vh;
  position: relative;
  @media (min-width: $xl) {
    padding-top: 18rem;
  }
  .main-title {
    position: absolute;
    bottom: 2rem;
    @media (min-width: $lg) {
      bottom: 30vh;
    }
  }
  h1 {
    font: 600 2rem 'Open Sans Condensed', sans-serif;
    text-shadow: 0 0 12px #000;
    @media (min-width: $xl) {
      font-size: 3.5rem;
    }
    &:after {
      content: '';
      background: $gold;
      display: block;
      height: 3px;
      margin: 1.5rem 0;
      width: 250px;
    }
  }
  p {
    margin-bottom: 0;
    max-width: 800px;
    text-shadow: 0 0 6px #000;
  }
  //&:before {
  //  content: '';
  //  display: block;
  //  background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
  //  height: 150px;
  //  position: absolute;
  //  width: 100%;
  //}
  //&:after {
  //  content: '';
  //  background: url('assets/images/mountains.png') repeat-x;
  //  display: block;
  //  height: 131px;
  //  width: 100%;
  //}
}

#top-subpage {
  height: 40vh;
}

section.page-content, #apartamenty, #opinie, #aktualnosci, #kontakt, #o-nas, #ogrod, #atrakcje, #galeria, #kalendarz, #polityka-prywatnosci {
  text-align: center;
  h2 {
    border-bottom: 1px solid $gold;
    color: $gold;
    display: inline-block;
    font: 600 3rem 'Open Sans Condensed', sans-serif;
    margin: 5rem 0 1rem;
    padding-bottom: 1rem;
    @media (min-width: $lg) {
      font-size: 3.75rem;
    }
  }
  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }
}

#apartamenty {
  .card {
    .card-title {
      a {
        color: #363636;
        text-decoration: none;
        &:hover, &:focus {
          text-decoration: underline;
        }
      }
    }
    .card-text {
      font-size: .85rem;
      > span {
        width: 33%;
      }
      img {
        width: 2.25rem;
        height: 2.25rem;
        margin-bottom: .25rem;
      }
    }
    img.main {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-title {
    border-bottom: 1px solid $gold;
    color: $gold;
    font: 600 2rem 'Open Sans Condensed', sans-serif;
    padding-bottom: .5rem;
  }
  .modal-content {
    background-image: url('../public/assets/images/parzenica.png');
    background-repeat: no-repeat;
    background-position: 580px 400px;
    .images {
      img {
        margin: .25rem;
        object-fit: cover;
        width: 11rem;
        height: 11rem;
      }
    }
    .equipment {
      font-size: .7rem;
      > div {
        flex-grow: 1;
        flex-basis: 0;
      }
      img {
        width: 2rem;
        height: 2rem;
        margin-bottom: .25rem;
      }
    }
    h6 {
      color: $gold;
      font-weight: 600;
    }
    ul {
      > li {
        display: flex;
        &::before {
          content: '';
          background: url('../public/assets/images/icons/check-mark.svg') no-repeat left center;
          background-size: .75rem;
          width: 1rem;
        }
      }
    }
  }
  .modal-body {
    padding-top: 0;
  }
}

#opinie {
  background-image: url('../public/assets/images/parzenica.png'), url('../public/assets/images/parzenica.png');
  background-repeat: no-repeat;
  background-position: -220px -130px, 800px 170px;
  background-color: #0b0c0c;
  border: solid $gold;
  border-width: 3px 0 20px;
  color: $gold;
  h2 {
    border-color: $gold;
    color: $gold;
  }
  //a {
  //  color: $gold;
  //  text-decoration: underline;
  //  &:focus, &:hover {
  //    color: #fff;
  //  }
  //}
  .review {
    background: rgba(51, 53, 51, 0.5);
    border-radius: 8px;
    color: #e9eaec;
    font: 600 16px 'Open Sans Condensed', sans-serif;
    padding: 1rem 1rem;
    @media (max-width: $md - 1) {
      display: flex;
      flex-direction: column;
      height: 360px;
      justify-content: center;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 12;
        line-clamp: 12;
        -webkit-box-orient: vertical;
      }
    }
    @media (min-width: $lg) {
      font-size: 18px;
      padding: 3rem 2rem;
    }
  }
}

#aktualnosci {
  h2 {
    border-color: #363636;
    color: #363636;
  }
  .carousel-caption {
    background: rgba(0,0,0,0.3);
    padding-left: .5rem;
    padding-right: .5rem;
    text-shadow: 0 0 10px #000;
  }
}

#kontakt {
  background: #363636;
  color: #d2c4a9;
  margin-bottom: -9rem;
  padding-bottom: 6rem;
}

#polityka-prywatnosci {
  ol {
    > li {
      margin-bottom: 1rem;
    }
    max-width: 800px;
    ul {
      list-style-type: lower-alpha;
    }
  }
}

#cookie-consent {
  box-shadow: rgba(0,0,0,0.5) 0 0 3rem;
  bottom: 4rem;
  right: 1rem;
}

iframe.map {
  border: 0;
  border-radius: 8px;
  height: 500px;
  margin: 1.5rem 0 3rem;
  width: 100%;
}

.list-group-item {
  background: none;
  border-color: #9b8559;
  color: #363636;
  display: flex;
  font: 600 16px 'Open Sans Condensed', sans-serif;
  padding: 1.8rem 0;
  text-align: left;
  i {
    margin-top: .2rem;
    margin-right: .4rem;
  }
  a {
    color: #363636;
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
  .btn {
    font-family: 'Open Sans', sans-serif;
  }
}

#footer {
  color: #fff;
  text-align: center;
  &:before {
    content: '';
    background: url('../public/assets/images/footer-mountains.png') repeat-x bottom;
    background-size: contain;
    display: block;
    height: 131px;
    width: 100%;
  }
  .footer-inner {
    background: #282828;
    padding: 2rem 0 2rem;
    a {
      color: $gold;
    }
  }
}

.fancybox__container {
  z-index: 1060;
}
